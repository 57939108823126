import { createApp }    from "vue";
import BootstrapVueNext from "bootstrap-vue-next";

import App      from "./App.vue";
import router   from "./router";
import store    from "./state/store";
import { i18n } from "./form-locales";
import Rollbar  from "rollbar";
import sentryLoader from './js/sentry';

import "@/vee-validate";
import "@/design/feedback-form.scss";
import "@/js/utils";

// // Axios configuration
import axios from "axios";
axios.defaults.baseURL =
  process.env.VUE_APP_BASE_SERVER == undefined
    ? "https://agora.olery.com"
    : process.env.VUE_APP_BASE_SERVER;
axios.defaults.params = {};
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";

axios.interceptors.request.use(
  function (config) {
    config.params["auth_token"] = process.env.VUE_APP_FEEDBACK_TOKEN;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const feedbackApp = createApp(App);
const sentry = sentryLoader(feedbackApp, router)

if (process.env.VUE_APP_ROLLBAR_TOKEN) {
  const rollbar = new Rollbar({
    accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: { environment: process.env.NODE_ENV }
  });

  feedbackApp.config.errorHandler = (err) => {
    rollbar.error(err);
    sentry.captureException(err);
    throw err; // rethrow
  };

  feedbackApp.config.productionTip = false;
  feedbackApp.config.globalProperties.$rollbar = rollbar;
}

feedbackApp.use(router);
feedbackApp.use(i18n);
feedbackApp.use(BootstrapVueNext);
feedbackApp.use(store);

feedbackApp.mount("#app");
