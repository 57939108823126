import { createI18n } from 'vue-i18n'

const formLocales = {
  messages: {},
    loadLocaleMessages() {
      const locales = require.context( "./form-locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
      locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          const locale = matched[1];
          this.messages[locale] = locales(key);
        }
      });
      return this.messages;
    }
}

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: formLocales.loadLocaleMessages()
})
export { formLocales, i18n }
